import { render, staticRenderFns } from "./UserDisplay.vue?vue&type=template&id=ad5f810a&scoped=true"
import script from "./UserDisplay.vue?vue&type=script&lang=js"
export * from "./UserDisplay.vue?vue&type=script&lang=js"
import style0 from "./UserDisplay.vue?vue&type=style&index=0&id=ad5f810a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad5f810a",
  null
  
)

export default component.exports