<template>
  <div class="dashboard-container">
    <div class="d-flex flex-1 flex-scroll-height ma-5">
      <div
        v-if="projectsStatus === 'success'"
        class="d-flex flex-column"
        style="width: 80%"
      >
        <div>
          <v-text-field
            v-model="dashboardSearch"
            clearable
            data-v-step="Dash_2"
            dense
            filled
            flat
            hide-details
            placeholder="Type here to search"
            prepend-inner-icon="mdi-magnify"
            @click:clear="dashboardSearch = ''"
          />
          <div
            v-if="filteredMasterModules.length > 0"
            class="pa-4 overflow-hidden"
          >
            <div class="master-modules-container">
              <ant-module-tile
                v-for="module in filteredMasterModules"
                :key="module.id"
                :module="module"
                style="height: auto !important"
                @click="openModule(module)"
              >
              </ant-module-tile>
            </div>
          </div>
        </div>

        <v-divider class="mb-4" />
        <div class="flex-1 overflow-y-auto">
          <v-subheader style="height: auto">
            {{ $t('system.projects.multi') }}
            <v-tooltip v-if="$can('access', 'project-archive')" bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  :color="showArchive ? 'primary' : ''"
                  class="ant-icon ml-2"
                  dense
                  v-bind="attrs"
                  @click="showArchive = !showArchive"
                  v-on="on"
                >
                  mdi-archive
                </v-icon>
              </template>
              <span>Archive</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  :color="projectState === 'grid' ? 'primary' : ''"
                  dense
                  v-bind="attrs"
                  @click="projectState = 'grid'"
                  v-on="on"
                >
                  mdi-dots-grid
                </v-icon>
              </template>
              <span>Project grid view</span>
            </v-tooltip>
            <v-divider class="mx-2" vertical />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  :color="projectState === 'list' ? 'primary' : ''"
                  dense
                  v-bind="attrs"
                  @click="projectState = 'list'"
                  v-on="on"
                >
                  mdi-format-list-bulleted
                </v-icon>
              </template>
              <span>Project list view</span>
            </v-tooltip>
          </v-subheader>
          <div
            v-if="projectState === 'grid'"
            class="dashboard-projects-container flex-grow-1 pa-4"
          >
            <project-card
              v-for="(project, index) in filteredProjects"
              :key="project.id"
              :class="{ 'v-step-Dash_1': index === 0 }"
              :project="project"
              favorite
              @favorite-change="updateProjectsFavoriteState"
            />
          </div>
          <div v-if="projectState === 'list'" class="overflow-y-auto px-2">
            <project-list-card
              v-for="project in filteredProjects"
              :key="project.id"
              :project="project"
              favorite
              @favorite-change="updateProjectsFavoriteState"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex justify-center align-center ma-4"
        style="width: 80%"
      >
        <ant-loading />
      </div>
      <div class="d-flex flex-column flex-1 ml-4">
        <user-display
          v-if="authenticatedUser"
          :user="authenticatedUser"
          class="mb-4"
          data-v-step="Dash_0"
        />

        <div class="d-flex flex-column flex-1">
          <div
            class="ant-glass-background pa-4 d-flex align-center navigation-item mb-4"
            data-v-step="Dash_3"
            @click="openTaskSidebar"
          >
            <v-icon>mdi-clipboard</v-icon>
            <v-divider class="mx-2" vertical />
            Today's Tasks
          </div>
          <div
            class="ant-glass-background pa-4 d-flex align-center navigation-item mb-4"
            data-v-step="Dash_4"
            @click="$router.push('/workflow-management')"
          >
            <v-icon>mdi-source-repository-multiple</v-icon>
            <v-divider class="mx-2" vertical />
            My workflows
          </div>
          <div
            class="ant-glass-background pa-4 d-flex align-center navigation-item"
            data-v-step="Dash_5"
            @click="$router.push('/settings')"
          >
            <v-icon>mdi-account-edit</v-icon>
            <v-divider class="mx-2" vertical />
            Profile settings
          </div>
          <v-spacer />
          <div
            class="ant-glass-background pa-4 d-flex align-center navigation-item mb-4"
            data-v-step="Dash_6"
            @click="openSupport"
          >
            <v-icon>mdi-help-rhombus</v-icon>
            <v-divider class="mx-2" vertical />
            Support
          </div>
          <div
            class="ant-glass-background pa-4 d-flex align-center navigation-item"
            @click="logout"
          >
            <v-icon>mdi-power</v-icon>
            <v-divider class="mx-2" vertical />
            Logout
          </div>
        </div>
      </div>
    </div>
    <v-tour
      :callbacks="{
        onFinish: saveTourState,
        onSkip: saveTourState,
      }"
      :name="tourName"
      :steps="tourSteps"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CookieService from '@/services/cookie';
import { dashboardTour } from '@/lang/onboarding';
import AntLoading from '@/components/AntLoading';
import LocalStorageService from '@/services/local-storage';
import ProjectListCard from '@/components/Dashboard/ProjectListCard.vue';
import AntModuleTile from '@/components/AntModuleTile.vue';
import appConfig from '@/appConfig';

export default {
  name: 'Dashboard',
  components: {
    AntModuleTile,
    ProjectListCard,
    AntLoading,
    ProjectCard: () =>
      import(
        /* webpackChunkName: "Dashboard" */ '../components/Dashboard/ProjectCard'
      ),
    UserDisplay: () =>
      import(
        /* webpackChunkName: "Dashboard" */ '../components/Dashboard/UserDisplay'
      ),
  },
  data: () => {
    return {
      tourName: 'dashboardTour',
      dashboardSearch: '',
      taskFinishedStatuses: ['completed', 'failed', 'cancelled'],
      projectState: 'grid',
      showArchive: false,
      tourSteps: dashboardTour('en'),
    };
  },
  computed: {
    ...mapGetters([
      'authenticatedUser',
      'authenticatedUser',
      'projects',
      'projectsStatus',
      'tasks',
      'masterProject',
      'selectedLicense',
      'sidebarTasks',
    ]),
    filteredProjects() {
      let projects = this.showArchive
        ? this.projects.filter((p) => p.is_archive)
        : this.projects.filter((p) => !p.is_archive);

      if (this.dashboardSearch.length > 0) {
        const searchLower = this.dashboardSearch.toLowerCase();

        projects = projects.filter(
          (project) =>
            project.name.toLowerCase().includes(searchLower) ||
            project.number.toLowerCase().includes(searchLower)
        );
      }

      projects = projects.filter((project) => {
        if (this.authenticatedUser.is_systemadmin) {
          return project;
        } else {
          if (!project?.is_master) {
            return project;
          }
        }
      });

      return projects.sort(
        (a, b) =>
          +b.is_favorite - +a.is_favorite || a.name.localeCompare(b.name)
      );
    },
    filteredMasterModules() {
      if (this.masterProject) {
        let modules = [
          ...this.masterProject.modules.filter((m) => m.accessible),
        ];
        if (this.dashboardSearch !== null) {
          modules = modules.filter((module) =>
            module.name
              .toLowerCase()
              .includes(this.dashboardSearch.toLowerCase())
          );
        }

        return modules.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return [];
      }
    },
  },
  watch: {
    projects(list) {
      let masterProject = list.find(
        (project) =>
          (project?.master_id === project?.id || project.is_master) &&
          project.license === this.selectedLicense.id
      );
      if (masterProject) {
        this.$store.commit('set_master_project', masterProject);
      } else {
        this.$store.commit('set_master_project', null);
      }
    },
    projectState(value) {
      CookieService.setProjectViewState(value, -1);
    },
    selectedLicense: {
      immediate: true,
      handler() {
        this.fetchTasks();
        this.$store.dispatch('fetchProjects', { loadImages: true }).then(() => {
          if (CookieService.getProjectViewState()) {
            this.projectState = CookieService.getProjectViewState();
          }
        });
      },
    },
  },
  mounted() {
    this.$store.commit('set_project', null);

    if (
      !CookieService._getCookie(this.tourName) &&
      this.selectedLicense.id === '911e928c-47f8-11ee-977b-0242ac120009'
    ) {
      this.$tours[this.tourName].start();
    }
  },
  methods: {
    saveTourState() {
      CookieService._setCookie(this.tourName, 1);
    },
    openSupport() {
      window.open(
        `${
          appConfig.VUE_APP_COLLABORALL_PORTAL_URL
        }/login?token=${LocalStorageService.getToken()}&provider=${
          appConfig.VUE_APP_COLLABORALL_PORTAL_PROVIDER
        }`,
        '_blank'
      );
    },
    logout() {
      LocalStorageService.removeToken();
      LocalStorageService.removeMaintenanceToken();
      this.$router.push({ name: 'Login' });
    },
    openTaskSidebar() {
      this.$store.commit('toggle_tasks_sidebar');
    },
    fetchTasks() {
      this.$store.dispatch('fetchSidebarTasks');
    },
    openModule(module) {
      this.$store.dispatch('setProject', this.masterProject);

      this.$router.push(
        `/project/${this.masterProject.slug}/module/${module.route}`
      );
    },
    updateProjectsFavoriteState() {
      this.$store.dispatch('reloadProjects');
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  background-image: linear-gradient(-90deg, #e6e9f0 0%, #eef1f5 100%);
  background-blend-mode: lighten;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .master-modules-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 1rem;
    overflow: auto;
    padding: 8px;

    .master-module {
      cursor: pointer;
      transition: 200ms ease-out;
      display: flex;
      flex-direction: column;
      padding: 12px;
      justify-content: center;
      align-items: center;

      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        transform: scale(1.07);
      }

      .master-module-name {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }

  .dashboard-projects-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 150px;
    grid-gap: 16px;
    overflow-y: auto;
  }

  .navigation-item {
    cursor: pointer;
    transition: 200ms ease-out;

    &:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      color: var(--v-primary-base);
      transform: translateX(-7px);
    }
  }
}
</style>
